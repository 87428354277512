// src/App.js
import React from 'react';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import NavMenu from './NavMenu';
import './App.css';
import LeadList from './Insights/LeadList';
import LeadDetail from './Insights/LeadDetail';
import Playground from './Insights/Playground';
import ClientSelect from './Insights/ClientSelect';
import { UserProvider } from './UserContext';
import { RecordingsPage } from './pages/recordings';
import { ConfigProvider, Layout } from 'antd';
import { AgentLeads } from './pages/agentLeads';
import { RecordingPage } from './pages/recordings/Recording';
import { OutreachPage } from './pages/outreach';
import { MagicLeads } from './pages/agentLeads/leads-page';
import { PlaygroundPage } from './pages/playground';
import { PrivacyStatement } from './pages/privacyStatement';
import { LeadsSendListings } from './pages/agentLeads/listings/leads-send-listings-page';
import { PushToSendPage } from './pages/agentLeads/pushToSend';
import { PushToSendEmailPage } from './pages/agentLeads/pushToSendEmail';
import { RolfInternal } from './pages/rolf-internal/page';
import { TaskReviewPage } from './pages/taskReview/page';
import { DemoPage } from './pages/demo/page';
import { ErrorPage } from './pages/error';
import { AppointmentReviewPage } from './pages/appointmentReview/page';
import NurtureLeads from './pages/nurture';
import ViewConfig from './pages/config/page';
import { AgentSmsPage } from './pages/agentSms';

const { Content } = Layout;

function App(): JSX.Element {
  const apiUrl = process.env.REACT_APP_API_URL || 'localhost:7071';
  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: '#5b1eaf',
          colorInfo: '#5b1eaf',
          fontFamily: 'Roboto',
        },
      }}
    >
      <UserProvider>
        <Router>
          <Layout
            className="App"
            style={{ backgroundColor: '#F7F9FF', minHeight: '100vh' }}
          >
            {/* <Header> */}
            <NavMenu />
            {/* </Header> */}
            <Content style={{ padding: '0 5%' }}>
              <Routes>
                <Route
                  element={
                    <>
                      <ClientSelect />
                    </>
                  }
                  path="/"
                />
                <Route
                  element={
                    <>
                      <LeadList apiUrl={apiUrl} />
                    </>
                  }
                  path="/leads/:clientID"
                />
                <Route
                  element={
                    <>
                      <LeadDetail apiUrl={apiUrl} />
                    </>
                  }
                  path="/lead/:clientID/:leadID/:batchID?"
                />
                <Route
                  element={
                    <>
                      <Playground apiUrl={apiUrl} />
                    </>
                  }
                  path="/playground/:clientID/:leadID"
                />
                <Route
                  element={
                    <>
                      <RecordingsPage apiUrl={apiUrl} />
                    </>
                  }
                  path="/recordings/:clientId"
                />
                <Route
                  element={
                    <>
                      <RecordingPage apiUrl={apiUrl} isInternal={false} />
                    </>
                  }
                  path="/aisummary/:clientId/:recordingId"
                />
                <Route
                  element={
                    <>
                      <RecordingPage apiUrl={apiUrl} isInternal={true} />
                    </>
                  }
                  path="/recordings/:clientId/:recordingId"
                />

                <Route
                  element={
                    <>
                      <OutreachPage apiUrl={apiUrl} />
                    </>
                  }
                  path="/outreach/:clientId/"
                />
                <Route
                  element={
                    <>
                      <AgentSmsPage apiUrl={apiUrl} />
                    </>
                  }
                  path="/agentSms/:clientId/"
                />
                <Route
                  element={
                    <>
                      <AgentLeads apiUrl={apiUrl} />
                    </>
                  }
                  path="/agentLeads/:clientId/:agentId?"
                />
                <Route
                  element={
                    <>
                      <MagicLeads apiUrl={apiUrl} />
                    </>
                  }
                  path="/leads2/:clientId/:agentId?"
                />
                <Route
                  element={
                    <>
                      <LeadsSendListings apiUrl={apiUrl} />
                    </>
                  }
                  path="/leads2/:clientId/:agentId/sendListings"
                />
                <Route
                  element={
                    <>
                      <PushToSendPage apiUrl={apiUrl} />
                    </>
                  }
                  path="/leads2/:clientId/:agentId/pts"
                />
                <Route
                  element={
                    <>
                      <PushToSendEmailPage apiUrl={apiUrl} />
                    </>
                  }
                  path="/leads2/:clientId/:agentId/ptsemail"
                />
                <Route
                  element={
                    <>
                      <NurtureLeads apiUrl={apiUrl} />
                    </>
                  }
                  path="/nurture/:clientId/:agentId"
                />

                <Route
                  element={
                    <>
                      <PlaygroundPage apiUrl={apiUrl} />
                    </>
                  }
                  path="/playgroundv2"
                />
                <Route
                  element={
                    <>
                      <RolfInternal apiUrl={apiUrl} />
                    </>
                  }
                  path="/rolf-internal"
                />
                <Route
                  element={
                    <>
                      <TaskReviewPage apiUrl={apiUrl} />
                    </>
                  }
                  path="/task-review/:clientId/:recordingID"
                />
                <Route
                  element={
                    <>
                      <ViewConfig apiUrl={apiUrl} />
                    </>
                  }
                  path="/view-config/"
                />
                <Route
                  element={
                    <>
                      <AppointmentReviewPage apiUrl={apiUrl} />
                    </>
                  }
                  path="/appointment-review/:clientId/:recordingID"
                />
                <Route
                  element={
                    <>
                      <DemoPage apiUrl={apiUrl} />
                    </>
                  }
                  path="/demo"
                />
                <Route
                  element={
                    <>
                      <PrivacyStatement apiUrl={apiUrl} />
                    </>
                  }
                  path="/privacyStatement"
                />
                <Route
                  element={
                    <>
                      <ErrorPage apiUrl={apiUrl} />
                    </>
                  }
                  path="/error"
                />
              </Routes>
            </Content>
          </Layout>
        </Router>
      </UserProvider>
    </ConfigProvider>
  );
}

export default App;
