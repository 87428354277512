import { useEffect, useRef, useState } from 'react';
import { Agent, AgentMessage } from '../../types';
import { Button, Flex, Input, Popconfirm, Spin } from 'antd';
import ChatBubble from '../../components/ChatBubble';
import { SendOutlined } from '@ant-design/icons';

interface ConversationProps {
  agent: Agent;
  apiUrl: string;
}
export const Conversation = ({
  agent,
  apiUrl,
}: ConversationProps): JSX.Element => {
  const [messages, setMessages] = useState<AgentMessage[] | undefined>(
    undefined,
  );
  // const [limit, setLimit] = useState<number>(20);
  const limit = 20;
  const [offset, setOffset] = useState<number>(0);
  const [message, setMessage] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(true);
  const [sendLoading, setSendLoading] = useState<boolean>(false);
  const listRef = useRef<HTMLDivElement>(null);
  const shouldScroll = useRef<boolean>(false);
  const [expandedMessages, setExpandedMessages] = useState<Set<string>>(
    new Set(),
  );

  const fetchMessages = async (
    agent: Agent,
    limit: number,
    offset: number,
    reset: boolean,
  ) => {
    try {
      setLoading(true);
      const url = `${apiUrl}/api/messaging/agent/${agent.id}?limit=${limit}&offset=${offset}`;
      const response = await fetch(url);
      const jsonData = await response.json();
      if (reset) {
        setOffset(jsonData.length);
        setMessages(jsonData);
        setExpandedMessages(new Set());
      } else {
        setMessages(prev =>
          [...jsonData, ...(prev ?? [])].sort(
            (a, b) =>
              new Date(a.created_at).getTime() -
              new Date(b.created_at).getTime(),
          ),
        );
        setOffset(prev => prev + limit);
      }
    } catch (err) {
      console.error('An error occurred while fetching the lead:', err);
      setMessages([]);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    shouldScroll.current = true;
    fetchMessages(agent, limit, 0, true).then().catch(console.log);
  }, [agent]);

  useEffect(() => {
    if (shouldScroll.current) {
      const timeout = setTimeout(() => {
        if (listRef.current) {
          listRef.current.scrollTop = listRef.current.scrollHeight + 1;
          shouldScroll.current = false;
        }
      }, 500); // Wait for half second
      return () => clearTimeout(timeout);
    }
  }, [messages, offset]);

  const send = async () => {
    try {
      setSendLoading(true);
      const url = `${apiUrl}/api/calls/sms`;
      const response = await fetch(url, {
        method: 'POST',
        body: JSON.stringify({
          agentId: agent.id,
          message: message,
        }),
      });
      if (!response.ok) {
        throw new Error(`Failed to send message ${response.text}`);
      }
      setMessage('');
      await fetchMessages(agent, 1, 0, false);
    } catch (err) {
      console.error('An error occurred while fetching the lead:', err);
    } finally {
      setSendLoading(false);
    }
  };

  const toggleExpand = (messageId: string) => {
    setExpandedMessages(prev => {
      const newSet = new Set(prev);
      if (newSet.has(messageId)) {
        newSet.delete(messageId);
      } else {
        newSet.add(messageId);
      }
      return newSet;
    });
  };

  return (
    <div>
      {/* <AlertBanner error={error} /> */}
      <h2>
        {agent.first_name} {agent.last_name}
      </h2>
      <Spin spinning={loading}>
        <Flex
          ref={listRef}
          style={{ height: '70vh', overflowY: 'auto' }}
          vertical
        >
          <Button
            onClick={() => void fetchMessages(agent, limit, offset, false)}
          >
            Load More
          </Button>
          {messages?.map((message, index) => (
            <>
              <ChatBubble
                isOwnMessage={!message.incoming}
                key={index}
                message={message.message}
                onClick={() => toggleExpand(message.id)}
                timestamp={message.created_at}
              />
              {expandedMessages.has(message.id) && (
                <div
                  style={{
                    marginLeft: '20px',
                    marginTop: '5px',
                    color: 'gray',
                    textAlign: message.incoming ? 'left' : 'right',
                  }}
                >
                  <div>ID: {message.id}</div>
                  {message.meta && (
                    <div>{JSON.stringify(message.meta, null, 2)}</div>
                  )}
                </div>
              )}
            </>
          ))}
        </Flex>
        <Flex gap="small" justify="flex-end">
          <Input
            onChange={e => setMessage(e.target.value)}
            style={{ flex: 0.8 }}
            value={message}
          />
          <Popconfirm
            cancelText="No"
            description="Are you sure you want to send to agent?"
            okText="Send"
            onConfirm={() => void send()}
            title="Send text"
          >
            <Button
              icon={<SendOutlined />}
              loading={sendLoading}
              type="primary"
            ></Button>
          </Popconfirm>
        </Flex>
      </Spin>
    </div>
  );
};
